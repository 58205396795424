$(document).ready(function () {
  const $iataAgentInputs = $('.js-iata-agent');

  window.initializeIATAInput = initializeIATAInput;
  window.validateInvalidDuplicatedFields = validateInvalidDuplicatedFields;

  if (!$iataAgentInputs.length) {
    return;
  }

  $iataAgentInputs.on('change', onChangeHandler);

  function initializeIATAInput($container) {
    const $iataInput = $container.find('.js-iata-agent');
    $iataInput.on('change', onChangeHandler);
    $iataInput.on('clearRelatedFields', onClearRelatedFieldsHandler);
  }

  $iataAgentInputs.on('clearRelatedFields', onClearRelatedFieldsHandler);

  function onClearRelatedFieldsHandler() {
    const $this = $(this);

    const $iataRelatedInputs = getRelatedFields($this);

    if (!$iataRelatedInputs.length) {
      return;
    }

    $iataRelatedInputs.each(function (index, element) {
      const $element = $(element);
      const inputDataField = $element.data('inputData');

      if (inputDataField) {
        window.updateDataField($element, '');
      }
    });
  }

  function getRelatedFields($iataAgentInput) {
    const inputData = $iataAgentInput.data('inputData').split(' ');
    const relatedInputSelector = `.${inputData[0]}`;
    let $iataRelatedInputs = null;

    if (inputData.indexOf('noContainer') > -1) {
      $iataRelatedInputs = $(relatedInputSelector);
    } else {
      $iataRelatedInputs = $iataAgentInput.closest('.js-iata-tids-number').find(relatedInputSelector);
    }

    return $iataRelatedInputs;
  }

  function validateInvalidDuplicatedFields() {
    var $iataInputs = $('.js-iata-agent');

    $('.js-iata-agent.user-error').each(function (index, element) {
      var $iataAgentInput = $(element);
      var checkedValue = $iataAgentInput.val();

      if (checkedValue) {
        var $otherIataInputs = $iataInputs.not($iataAgentInput);

        if ($otherIataInputs.length === 0 || !$otherIataInputs.val()) {
          populateRelatedFields($iataAgentInput);
        }

        var isDuplicated = false;
        $otherIataInputs.each(function (index, otherInput) {
          isDuplicated = isDuplicated || isValueDuplicated(checkedValue, $(otherInput));
        });

        if (!isDuplicated) {
          populateRelatedFields($iataAgentInput);
        }
      }
    });
  }

  function isValueDuplicated(checkedValue, $inputs) {
    var isDuplicated = false;
    $inputs.each(function (index, otherInput) {
      var $otherInput = $(otherInput);
      if ($otherInput.val() === checkedValue) {
        isDuplicated = true;
      }
    });

    return isDuplicated;
  }

  function isDuplicated($iata) {
    var currentValue = $iata.val();
    var result = [];

    $('.js-iata-agent').not($iata).each(function (index, element) {
      var $element = $(element);

      if ($element.val()) {
        result.push($(element).val());
      }
    });

    return result.indexOf(currentValue) > -1;
  }

  function populateRelatedFields($iataAgentInput) {
    const $iataRelatedInputs = getRelatedFields($iataAgentInput);
    const value = $iataAgentInput.val();

    if (!$iataRelatedInputs.length) {
      return;
    }

    window.APICaller.validateIATA({ iataCode: value }).then(function (returnedData) {
      $iataAgentInput.setCustomValidity('');
      $iataAgentInput.trigger('resetvalidation');

      $iataRelatedInputs.each(function (index, element) {
        const $element = $(element);
        const inputDataField = $element.data('input-data');
        if (inputDataField) {
          window.updateDataField($element, returnedData[inputDataField]);
          $element.trigger('change');
        }
      });

      $iataAgentInput.trigger('iata-agent-data', returnedData);

      // populate phone code
      const phoneCodeFormat = `${returnedData.countryName} (+${returnedData.phoneCode})`;
      window.updateDataField($('.js-account-registration-phone-code'), phoneCodeFormat);


      // populate states/cities
      // todo refactor this
      if ($iataAgentInput.attr('name') === 'iataAgentPrimary') {
        window.populateAccountRegistrationStates(returnedData.states);
        window.populateAccountRegistrationCities(returnedData.cities);
      }
    }, function (error) {
      $iataAgentInput.setCustomValidity(error);
      $iataAgentInput.trigger('updatevalidation');
      $iataAgentInput.trigger('iata-agent-data', {});

      $iataRelatedInputs.each(function (index, element) {
        const $element = $(element);
        if(index > 0) {
          window.updateDataField($element, '');
          $element.trigger('change');
        }
      });
    });
  }

  function onChangeHandler() {
    const $iataAgentInput = $(this);
    const value = $iataAgentInput.val();
    if (value.length < 8) {
      validateInvalidDuplicatedFields();

      return;
    }

    if (isDuplicated($iataAgentInput)) {
      $iataAgentInput.setCustomValidity('This code exists, please enter another');
      $iataAgentInput.trigger('updatevalidation');
      return;
    } else {
      $iataAgentInputs.setCustomValidity('');
      $iataAgentInputs.trigger('resetvalidation');
    }

    populateRelatedFields($iataAgentInput);
  }
});
